import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
export default {
  name: "video",
  components:{
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchContent(this.$route.path.split('/').pop())
      }
    }
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchContent(this.$route.path.split('/').pop())
  },
  computed:{
    ...mapGetters({
      videoList: 'pages/page'
    }),
  },

  methods:{
    ...mapActions({
      fetchContent: 'pages/GET_PAGE'
    }),
  }
}